.login-body {
    margin: 0 auto;
    padding: 30px 40px;
    width: 100%;
    max-width: 20%;
    min-width: 380px;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    border: 1px solid #c155ec;


}
.admin-login-bg {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background-image: url("../images/admin-login-en.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.admin-login-he {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background-image: url("../images/admin-login.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.admin-logo {
    padding-bottom: 64px;
}

.logo-img {
    margin: 0 auto;
    width: 100%;
    max-width: 308px;
}
.btn-common.forgot {
    width: 40%;
}

/**
 * Register
 */
.register-body {
    margin: 0 auto;
    padding: 25px 20px;
    width: 100%;
    max-width: 25%;
    min-width: 380px;
    background: rgba(255, 255, 255, 0.85);
}
input{
    padding: 10px;
    /* margin-bottom: 10px; */
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
    outline: none;
}

.login-image {
    position: absolute;
    top: 40px;
    left: 50px; 
    width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* מבטיח שהתמונה תישאר למעלה */
    padding: 10px;
    /* padding-left: 20px; */
}

.login-image img {
    max-width: 100%;
    /* height: auto; */
    object-fit: contain;
}