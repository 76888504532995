/**
 * Header Component
 */
 @media screen and (max-width: 1200px){
    .btn-navbar {
        margin-left: 15px !important;
    }
}
@media screen and (max-width: 992px) {
    .btn-navbar.price {
        display: flex !important;
        justify-content: space-between !important;
    }
}

@media screen and (max-width: 768px) {
    .navbar-menu, .secondMenu {
        display: flex !important;
    }
    .btn-navbar {
        display: none !important;
    }
    .mobile-menu {
        display: block !important;
    }
    .header-height {
        background-color: #ffffff !important;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) !important;
    }
}

@media screen and (max-width: 600px) {
    .tablet-shown {
        display: none !important;
    }

    .phone-shown {
        display: block !important;
    }
}

@media screen and (max-width: 480px) {
    .logo-mw {
        padding: 15px !important;
    }
}

.header-height {
    display: flex;
    align-items: center;
    height: 90px;
    background-color: #A528D8;
}
.header-body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3000;
    /*background-image: linear-gradient(180deg, #fff8 50%, transparent 100%);*/
}
.header-body .header-logo {
    padding: 4px 0;
    height: 100%;
}
.header-body .header-logo img {
    height: 50px;
    object-fit: contain;
}
.header-body.white {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 #3333;
    opacity: 0.95;
}
.header-body.white .btn-navbar {
    color: #333;
}
.header-height.white {
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 #3333;
    opacity: 0.95;
}
.logo-mw {
    padding: 15px 30px;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    /* background-image: url("../assets/images/zing1.svg"); */
}

.btn-navbar {
    display: flex;
    margin-left: 20px;
    padding: 10px;

    border-radius: 4px;
    transition: none;
}

.btn-navbar:hover {
    color: #fff !important;
    background: #781e9c;
}

.btn-filter {
    display: flex;
    margin-top: 20px;
    padding: 10px 10px 10px 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-size: 18px;

    border-bottom: 1px solid var(--col-lightColor);
    transition: none;
}

.btn-filter:hover {
    color: #fff !important;
    background: #ccc;
}

.flex-filter {
    display: flex;
    justify-content: space-between;
}


.dropdown-filter {
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    border-radius: 6px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transition: all 0.5s ease-out;
    font-size: 14px;

    text-align: left;
    z-index: 1;
}
.filter-item {
    padding: 10px;
}
.filter-item:hover {
    background-color: #ccc;
}
.dropdown:hover .dropdown-filter {
    display: block;
}

.sign-border {
    padding: 6px 10px;
    border: 2px solid rgb(100, 211, 100);
    /*background-color: #43CD43;*/
}
.btn-sign {
    padding: 6px 10px;
    border: 2px solid #55156e;
    background-color: #55156e;
}
.btn-sign:hover,
.sign-border:hover {
    opacity: 0.7;
    background-color: #55156e;
}

.mobile-menu {
    display: none;
}

@keyframes ami {
    from { transform: scaleY(0);
    }
    to {
        transform: scaleY(1);
    }
}

.trans-menu {
    transform-origin: 0 0;
    animation: ami 0.3s ease-out;
}

.menu-hidden {
}

.hide {
    display: none;
}

.menu-container {
    position: absolute;
    width: 100%;
    max-width: 100%;
}

.navbar-menu,
.secondMenu {
    display: none;
    z-index: 3000;
    width: 100%;
    max-width: 100%;
    height: 70px;
    background-color: #fff;
    color: #333;
}

.navbar-menu:hover {
    background-color: #eee;
}

.btn-underLine:first-child .navbar-menu {
    border-top: 1px solid #abf2d3;
}

.secondMenu {
    height: 40px;
    background-color:  #fff;
}

.secondMenu:hover {
    background-color: #eee;
}

.menu-selectedBg {
    background-color: #eee;
}

.btn-navbar-bb {
    border-bottom: 1px solid var(--col-lightColor);
}

.tablet-shown {
    display: block;
}

.phone-shown {
    display: none;
}

/**
 * dropdown
 */
.dropdown {
    position: relative;
    display: inline-block;
    /* height: 40px; */
    color: #fff;
}

.dropdown-content {
    display: none;
    position: absolute;
    /* padding: 0 16px; */
    top: 40px;
    left: 0;
    border-radius: 6px;
    background-color: #fff;
    min-width: 155px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transition: all 0.5s ease-out;

    text-align: left;
    z-index: 1;
}


.dropdown-content.resource {
    min-width: 150px;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.menu-txt {
    padding: 10px 10px;
    width: 100%;
    color: #333;
}

.menu-txt:hover {
    /*color: var(--col-darkBlue);*/
    background-color: #eee;
}

.pl {
    padding-left: 30px;
}
.flex-more {
    display: flex;
    justify-content: space-between;
}
.drop-icon {
    padding-left: 10px;
    width: 22px;
}

.dropdown-icon {
    position: absolute;
    right: 20px;
}

.down-up-size {
    width: 20px;
    height: 20px;
}
.date-pickers-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

/* .test1{
    margin-right: 10px;
    box-shadow: #A528D8;
} */

.card-serch {
    margin-top: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
}


.btn-common-search:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    /* background-color: var(--col-heavyDark); */
    /* border-color: #A528D8; */
    /* color: white; */
    color: #A528D8;
    /* border: 1px solid #A528D8; */
    background-color: white;
    transform: scale(1.05); 

  
  }
  .btn-common-search {
    opacity: 0.7;
    background-color: var(--col-heavyDark);
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
    transform: scale(1.05); 
    width: 190px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    /* background-color: var(--col-heavyDark); */
    /* border-color: #A528D8; */
    /* color: #A528D8; */
    border: 1px solid #A528D8;

  }

.image-header {
    left: 20px;
    width: 80px;
    height: auto;
    margin-right: 40px
    /* background-image: url("../images/zing-white.png"); */
}

.image-header img {
    max-width: 100%;
    object-fit: contain;
}

/* הסתרת התמונה  */
@media screen and (max-width: 1024px) {
    .image-header {
        display: none;
    }
}

.logout-btn {
    background-color: red;
}

/**
      * CheckBox
      */
      .container-event {
        display: block;
        position: relative;
        padding-top: 3px;
        padding-bottom: 10px;
        padding-left: 25px;
        font-size: 0.9rem;
        color: #000000;
        cursor: pointer;
    }
    
    /* .container-event.all {
    
    } */
    .container-event.language {
        padding-bottom: 0;
    }
    
    .container-event input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    
    .checkMark {
        position: absolute;
        border: 1px solid #d9d9d9;
        top: 5px;
        left: 0;
        height: 17px;
        width: 17px;
        background-color: #ffffff;
    }
    
    .checkMark:hover {
        border: 1px solid var(--col-heavyDark);
    }
    
    .checkMark-gray {
        background-color: #e7e7f3;
    }
    
    /* .container-event:hover input ~ .checkMark {
        background-color: var(--col-heavyDark);
    }
    
    .container-event input:checked ~ .checkMark {
        background-color: var(--col-heavyDark);
    } */
    
    .checkMark:after {
        content: "";
        position: absolute;
        display: none;
    }
    
    .container-event input:checked~.checkMark:after {
        display: block;
    }
    
    .container-event .checkMark:after {
        left: 5px;
        top: 2px;
        width: 3px;
        height: 7px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(35deg);
    }
